<template>
	<div class="nearby-list" v-if="isShow && goodslists.length > 0">
		<van-list v-model="loading" :finished="finished" finished-text="到底啦〜想不到你看到了这里^_^" @load="getMore">
			<div class="nearby-grid" v-for="(item, index) in goodslists" :key="index">
				<div class="shop-grid">
					<router-link :to="'/business?shop_id=' + item.shop_id + '&distance=' + item.distance"
						class="routerto">
						<img :src="item.shop_logo" alt />
					</router-link>
					<div class="shop-info">
						<div class="shop-name">{{ item.shop_name }}</div>
						<div class="shop-time">地址：{{ item.shop_address }}</div>
						<div class="flex-row">
							<div class="shop-address" @click="callPhone(item.shop_phone)">
								<img src="../../assets/dianhua.png" alt />
								点击电话咨询
							</div>
							<div class="shop-distance" @click="openLocation(item)">
								<img src="../../assets/cp05.png" alt />
								距您：{{ item.distance | handleDistance }}
							</div>
						</div>
					</div>
				</div>

				<div class="goods-row" v-for="(item1, index) in item.goodslist" :key="index">
					<div class="goods-list">
						<div class="goods-grid">
							<router-link :to="'/details?goods_id=' + item1.goods_id + '&types=1'">
								<img :src="item1.cover_imgurl" alt />
							</router-link>
							<router-link :to="'/details?goods_id=' + item1.goods_id + '&types=1'">
								<div class="goods-name">{{ item1.goods_name }}</div>
							</router-link>
							<div class="goods-info">
								<div class="price-box">
									<div class="price">
										￥
										<span>{{ item1.sale_price }}</span>
									</div>
									<div class="price-old">￥{{ item1.market_price }}</div>
								</div>
								<div class="flex-box">
									<div class="goods-fan">
										<div class="goods-stock">库存:{{ item1.goods_stock }}</div>
										<div class="goods-commission" v-if="userInfo.user_types == 2">
											饭佣:￥{{ userInfo.referrer_lv == 1 ? item1.commission2 : item1.commission1 }}
										</div>
									</div>
									<div class="xian"></div>
									<div class="goods-hot">
										<div class="hot-num">{{ item1.total_visit | countVisit }}</div>
										<div>人气值</div>
									</div>
								</div>
							</div>

							<div class="btn-row">
								<router-link :to="'/classify?id=' + item1.cate_id">
									<div class="btn green">
										{{ item1.cate_name }}
										<img src="@/assets/shouzhi.png" alt />
									</div>
								</router-link>

								<router-link :to="'/details?goods_id=' + item1.goods_id + '&types=1'">
									<div class="btn"
										:class="{ disable: item1.sale_status == 1,start: item1.sale_status == 2, out: item1.sale_status == 0}">
										{{ item1.sale_status | btnText }}
									</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
	import {
		cardsListsByDistance
	} from "@/http/api";
	import {
		changeDis,
		changeVisit
	} from "@/common/data";
	import {
		openLocation,
		getLocationInfo
	} from "@/common/wxJssdk";

	export default {
		components: {},
		data() {
			return {
				site_code: '',
				location: {},
				isShow: false,
				isLoad: true,
				pages: 1,
				loading: false,
				finished: false,
				goodslists: []
			};
		},
		mounted() {
			this.userInfo = this.$LStorage.getItem('userInfo');
			let that = this;
			getLocationInfo("gcj02", function(locationData) {
				console.log(locationData)
				let curSiteInfo = that.$LStorage.getItem('curSiteInfo');
				that.site_code = curSiteInfo.site_code
				if (locationData) {
					that.location = {
						location_lng: locationData.longitude,
						location_lat: locationData.latitude,
					}
				} else {
					that.location = {
						location_lng: curSiteInfo.location_lng,
						location_lat: curSiteInfo.location_lat,
					}
				}
				that.getCardsListsByDistance();
			});
		},

		methods: {
			//  今日爆款
			async getCardsListsByDistance() {
				if (this.isLoad) {
					const res = await cardsListsByDistance({
						data: {
							pages: this.pages,
							site_code: this.site_code,
							location_lng: this.location.location_lng,
							location_lat: this.location.location_lat,
						}
					});
					this.isShow = true;
					this.loading = false;
					if (res.numPage == res.data.length) {
						this.pages++;
						this.finished = false;
					} else {
						this.isLoad = false;
						this.finished = true;
					}
					this.goodslists = this.goodslists.concat(res.data);
				}
			},

			//  执行加载更多
			getMore() {
				this.getCardsListsByDistance();
			},

			//  拨打电话
			callPhone(tel) {
				window.location.href = "tel:" + tel;
			},

			//  跳转地图
			openLocation(item) {
				openLocation({
					latitude: parseFloat(item.location_lat), // 纬度，浮点数，范围为90 ~ -90
					longitude: parseFloat(item.location_lng), // 经度，浮点数，范围为180 ~ -180。
					name: item.shop_name, // 位置名
					address: item.shop_address // 地址详情说明
				});
			}
		},

		computed: {},

		filters: {
			handleDistance(distance) {
				return changeDis(distance);
			},
			//  设置样式
			btnText(saleStatus) {
				let text = "";
				if (saleStatus == 0) {
					text = "已结束";
				} else if (saleStatus == 1) {
					text = "暂未开始";
				} else if (saleStatus == 2) {
					if (saleStatus > 0) {
						text = "立即抢购";
					} else {
						text = "抢光了";
					}
				}
				return text;
			},
			countVisit(total_visit) {
				return changeVisit(total_visit);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.nearby-list {
		* {
			box-sizing: border-box;
		}

		box-sizing: border-box;
		min-height: 100vh;
		width: 100vw;
		background: #f6f6f6;

		.nearby-grid {
			width: 100vw;
			padding: 0 4vw;
			background: #fff;
			margin-bottom: 4vw;

			.shop-grid {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 4vw 0;

				img {
					width: 20vw;
					height: 20vw;
					border-radius: 5px;
					object-fit: cover;
				}

				.shop-info {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: 24vw;
					width: 68vw;
					font-size: 0.26rem;

					.shop-name {
						font-size: 0.32rem;
						font-weight: bold;

					}

					.shop-time {
						max-height: 9.5vw;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-box-orient: vertical;
					}

					.flex-row {
						display: flex;
						align-items: center;
						justify-content: space-between;
					}

					.shop-address {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						img {
							width: 0.36rem;
							height: auto;
						}
					}

					.shop-distance {
						color: #d93330;
						display: flex;
						align-items: center;

						img {
							width: 0.3rem;
							height: 0.36rem;
							margin-right: 0.1rem;
						}
					}
				}
			}

			.goods-row {
				overflow-x: scroll;
			}

			.goods-list {
				display: flex;
				align-items: center;
			}

			.goods-grid {
				// padding: 0 3px;
				width: 92vw;

				img {
					width: 92vw;
					height: auto;
					margin-bottom: 10px;
				}

				.goods-name {
					font-size: 0.32rem;
					line-height: 1.5;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
					overflow: hidden;
					margin-bottom: 10px;
				}

				.goods-info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 5px;
					border-top: 1px solid #eee;

					font-size: 0.26rem;

					.price-box {
						display: flex;
						align-items: baseline;

						.price {
							color: #d22d25;
							font-weight: bold;

							span {
								font-size: 0.6rem;
							}
						}

						.price-old {
							color: #999;
							text-decoration: line-through;
							margin-left: 5px;
						}
					}

					.flex-box {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.xian {
							background: #eee;
							height: 0.9rem;
							width: 1px;
							margin: 0 0.2rem;
						}

						.goods-fan {
							display: flex;
							flex-direction: column;
							align-items: flex-end;
							height: 1rem;
							justify-content: space-around;

							.goods-stock {
								color: #ee7b3a;
							}

							.goods-commission {
								background: #ee7b3a;
								color: #fff;
								height: 0.4rem;
								line-height: 0.4rem;
								padding: 0 5px;
								border-radius: 0.4rem;
							}
						}

						.goods-hot {
							display: flex;
							flex-direction: column;
							align-items: flex-end;
							height: 1rem;
							justify-content: space-evenly;
							color: #999;

							.hot-num {
								color: #ee7e3d;
							}
						}
					}
				}

				.btn-row {
					display: flex;
					align-items: center;
					justify-content: space-around;
					height: 1.8rem;

					.btn {
						background: #d22d25;
						width: 36.8vw;
						height: 10.8vw;
						border-radius: 10.8vw;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff;
						font-size: 0.28rem;
						line-height: 1;

						img {
							width: 0.4rem;
							margin-left: 5px;
							margin-bottom: 0;
						}
					}

					.green {
						background: #81b957;
					}

					.start {
						background: #e50012;
					}

					.disable {
						background: #b3b3b3;
					}

					.out {
						background: #e5e5e5;
					}
				}
			}
		}
	}
</style>
